import React from "react";
import { Grid, Typography } from "@mui/material";
import Container from "../../component/Container";

const BrandInformation = ({ sx, data, txt }) => {
  console.log(data);
  return (
    <Grid
      sx={{
        ...sx,
        backgroundColor: "white",
        width: ["87%", "88%", "90%"],
        // height: "calc(90vw * 0.2)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Container>

      <div className="mx-8 flex font-primary flex-col gap-4 md:flex-row justify-center md:gap-12">
        <div className=" w-[70px] h-[70px] md:h-[160px] md:w-[180px] lg:w-[201px] mx-auto">
          <img
            src={`https://mmmall.mn${data?.brandLogo}`}
            style={{ objectFit: "cover" }}
            className="w-full h-full rounded-2xl"
            alt="Brand Logo"
          />
        </div>

        <div className="flex flex-col md:gap-4  md:w-[435px] lg:w-[717px] ">
          <div className="flex w-full text-[10px] md:text-[14px] ">
            <h2 className="font-semibold">{data?.brandDetailDesc}</h2>
          </div>
          <div className="flex  text-[10px] lg:w-2/3 justify-between md:text-[14px]">
            <h1 className="font-semibold">{txt.phone} : </h1>
            <p className="font-bold text-right">{data?.brandDetailNumber}</p>
          </div>
          <div className="flex text-[10px] lg:w-2/3 justify-between md:text-[14px]">
            <h1 className="font-semibold"> {txt.email} : </h1>
            <p className="font-bold">{data?.brandDetailEmail}</p>
          </div>
          <div className="flex text-[10px] sm:pt-4 md:pt-0 lg:pt-0 lg:w-2/3 justify-between md:text-[14px]">
            <h1 className="font-semibold"> Сошиал холбоос : </h1>
            <div className="flex h-[40px]">
              {data?.brandDetailFacebook && (
                <a
                  className="social-icon__link mx-1 md:mx-3"
                  href={data?.brandDetailFacebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/img/Facebook_Logo.webp"
                    alt="fb"
                    className=" w-8 md:w-12"
                  />
                </a>
              )}
              {data?.web_url && (
                <a
                  className="social-icon__link mx-1 md:mx-3"
                  href={data?.web_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/img/web-icon.png"
                    alt="fb"
                    style={{ objectFit: "fill" }}
                    className=" w-14 md:w-20 "
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      </Container>
    </Grid>
  );
};
export default React.memo(BrandInformation);
