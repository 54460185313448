import React, { useState, useEffect } from "react";
import { Grid, Typography, Modal } from "@mui/material";
import Appbar from "../../component/Appbar";
import FooterMain from "../../component/footerMain";
import { LoadingOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import * as API from "../../api/request";
import Container from "../../component/Container";

const MrMisheel = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [url, setUrl] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    setLoading(true);
    API.getSocial("mrmisheel")
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.length > 0) {
            setData(res.data.data);
            console.log(data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Алдаа гарлаа.",
          text: "Зах зээлийн үнийн лист унших үед алдаа гарлаа дахин оролдоно уу.",
          confirmButtonColor: "#0f56b3",
        });
      })
      .finally(setLoading(false));
  }; // Function to extract video ID from YouTube URL
  // Function to extract video ID from YouTube URL
  const extractVideoId = (url) => {
    if (!url) {
      return null;
    }

    const urlObject = new URL(url);
    const videoId = urlObject.pathname.substring(1); // Remove the leading slash
    console.log(videoId);
    return videoId;
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Modal open={isModalVisible} onClose={() => setIsModalVisible(false)}>
        <Grid
          sx={{
            position: "absolute",
            width: "80%",
            marginTop: "10%",
            left: "50%",
            transform: "translate(-50%)",
          }}
        >
          <iframe
            width={"100%"}
            height={window.innerWidth < 600 ? 300 : 500}
            src={`https://www.youtube.com/${extractVideoId(url)}`}
            title="YouTube video player"
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Grid>
      </Modal>

      <Appbar />

        <div className="bg-white max-w-[1420px] mx-auto w-full items-center flex flex-col pt-[58px] justify-center">
          <div className="mx-auto">
            <h1 className="mt-4 text-[14px] mx-auto md:text-[31px] font-extralight">
              Mr.Misheel цуврал зөвлөгөө
            </h1>
          </div>

          <div className="mx-4 my-4">
            {loading === true ? (
              <LoadingOutlined />
            ) : (
              <div className="grid md:grid-cols-3 gap-8 md:gap-4">
                {data.map((item, index) => {
                  return (
                    <a
                      href={item.url}
                      target="_blank"
                      className="hover:text-black h-[400px]"
                    >
                      <div
                        className="flex h-full w-full flex-col bg-[#F5F5F5] font-primary rounded-xl"
                        key={index}
                        // Click handler for opening the video modal
                      >
                        <div className="w-full h-3/4">
                        <img
                          src={"https://mmmall.mn" + item.icon}
                          alt=""
                          className="rounded-t-xl h-full w-full"
                          style={{ objectFit: "cover" }}
                        />
                        </div>
                       
                        <div className="w-full py-4 md:py-8 font-semibold text-justify text-[12px] md:text-[14px]">
                          <div className="mx-2 ">{item.txt}</div>
                        </div>
                      </div>
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        </div>

      <FooterMain />
    </>
  );
};
export default React.memo(MrMisheel);
