import axios from "axios";
import Swal from "sweetalert2";

const URL = "https://mmmall.mn/api";
// const URL = "http://localhost:8002/api";

export function MISHEEL(headerParams = null) {
  return createInstance(URL, headerParams);
}

const createInstance = (baseURL, headerParams = null) => {
  const headers = {
    "Content-Type": headerParams ? "multipart/form-data" : "application/json",
  };
  const token =
    typeof window !== "undefined" ? localStorage.getItem("data") : null;

  if (token != null) {
    Object.assign(headers, {
      Authorization: "Bearer " + JSON.parse(token).accessToken,
    });
  }

  if (headerParams) {
    // Merge custom headers with default headers
    Object.assign(headers, headerParams);
  }

  let api = axios.create({
    baseURL: baseURL,
    timeout: 200000,
    headers,
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status !== 503) {
        if (error.message !== "Network Error") {
          if (error.response.status === 401) {
            // localStorage.removeItem("data");
          } else {
            throw error;
          }
        } else {
          Swal.fire({
            icon: "warning",
            text: "Сервис дээр алдаа гарлаа.",
            confirmButtonColor: "#0e965a",
          });
          throw error;
        }
      } else {
        Swal.fire({
          icon: "warning",
          text: "Сервис дээр алдаа гарлаа.",
          confirmButtonColor: "#0e965a",
        });
        throw error;
      }
    }
  );
  return api;
};

// Нүүр хуудас

export async function postImg(formData, headerParams = null) {
  headerParams = true;
  const response = await MISHEEL(headerParams).post("/admin", formData);
  return response;
}

export async function putImg(formData, id, headerParams = null) {
  headerParams = true;
  const response = await MISHEEL(headerParams).put("/admin/" + id, formData);
  return response;
}

export async function getImg(headerParams = null) {
  const response = await MISHEEL(headerParams).get("/admin");
  return response;
}

export async function deleteImg(id, headerParams = null) {
  const response = await MISHEEL(headerParams).delete("/admin/" + id);
  return response;
}

export async function getBanner(id, headerParams = null) {
  const response = await MISHEEL(headerParams).get("/banner/" + id);
  return response;
}

export async function postBanner(formData, headerParams = null) {
  headerParams = true;
  const response = await MISHEEL(headerParams).post("/banner", formData);
  return response;
}
// Нүүр хуудас
// Брэнд

export async function postBrand(formData, headerParams = null) {
  headerParams = true;
  const response = await MISHEEL(headerParams).post("/brand", formData);
  return response;
}

export async function putBrand(formData, id, headerParams = null) {
  headerParams = true;
  const response = await MISHEEL(headerParams).put("/brand/" + id, formData);
  return response;
}

export async function getBrand(headerParams = null) {
  const response = await MISHEEL(headerParams).get("/brand");
  return response;
}

export async function getOneBrand(id, headerParams = null) {
  const response = await MISHEEL(headerParams).get("/brand/" + id);
  return response;
}
export async function getFamiliarBrand(id, headerParams = null) {
  const response = await MISHEEL(headerParams).get("/brand/similiar/" + id);
  return response;
}

export async function deleteBrand(id, headerParams = null) {
  const response = await MISHEEL(headerParams).delete("/brand/" + id);
  return response;
}
// Брэнд
// Category
export async function postCategory(params, headerParams = null) {
  const response = await MISHEEL(headerParams).post("/category", {
    ...params,
  });
  return response;
}

export async function getCategory(headerParams = null) {
  const response = await MISHEEL(headerParams).get("/category");
  return response;
}

export async function postSubCategory(params, headerParams = null) {
  const response = await MISHEEL(headerParams).post("/subcategory", {
    ...params,
  });
  return response;
}

export async function getSubCategory(headerParams = null) {
  const response = await MISHEEL(headerParams).get("/subcategory");
  return response;
}
// Category
// Product
export async function postProduct(formData, headerParams = null) {
  headerParams = true;
  const response = await MISHEEL(headerParams).post("/product", formData);
  return response;
}

export async function getProduct(headerParams = null) {
  const response = await MISHEEL(headerParams).get("/product");
  return response;
}

export async function deleteProduct(id, headerParams = null) {
  const response = await MISHEEL(headerParams).delete("/product/" + id);
  return response;
}
// Product
// Price
export async function postPrice(formData, headerParams = null) {
  headerParams = true;
  const response = await MISHEEL(headerParams).post("/price", formData);
  return response;
}

export async function getPrice(headerParams = null) {
  const response = await MISHEEL(headerParams).get("/price");
  return response;
}

export async function deletePrice(id, headerParams = null) {
  const response = await MISHEEL(headerParams).delete("/price/" + id);
  return response;
}
// Cover
export async function postPriceCover(formData, headerParams = null) {
  headerParams = true;
  const response = await MISHEEL(headerParams).post("/priceHead", formData);
  return response;
}

export async function getPriceCover(headerParams = null) {
  const response = await MISHEEL(headerParams).get("/priceHead");
  return response;
}
// Cover
// Price
// Social
export async function postSocial(name, formData, headerParams = null) {
  headerParams = true;
  const response = await MISHEEL(headerParams).post("/" + name, formData);
  return response;
}

export async function putSocial(id, formData, headerParams = null) {
  headerParams = true;
  const response = await MISHEEL(headerParams).put("/coleader/" + id, formData);
  return response;
}

export async function getSocial(name, headerParams = null) {
  const response = await MISHEEL(headerParams).get("/" + name);
  return response;
}

export async function deleteSocial(name, id, headerParams = null) {
  const response = await MISHEEL(headerParams).delete("/" + name + "/" + id);
  return response;
}

export async function deleteColeader(id, data_id, headerParams = null) {
  const response = await MISHEEL(headerParams).delete(
    "/coleader/" + id + "/" + data_id
  );
  return response;
}
// Social
// About
export async function postAbout(formData, headerParams = null) {
  headerParams = true;
  const response = await MISHEEL(headerParams).post("/about", formData);
  return response;
}

export async function putAbout(id, formData, headerParams = null) {
  headerParams = true;
  const response = await MISHEEL(headerParams).put("/about/" + id, formData);
  return response;
}

export async function getAbout(headerParams = null) {
  const response = await MISHEEL(headerParams).get("/about");
  return response;
}

export async function deleteAboutRelated(id, headerParams = null) {
  const response = await MISHEEL(headerParams).delete("/about/related" + id);
  return response;
}

export async function getTimeline(headerParams = null) {
  const response = await MISHEEL(headerParams).get("/timeline");
  return response;
}

export async function postTimeline(formData, headerParams = null) {
  headerParams = true;
  const response = await MISHEEL(headerParams).post("/timeline", formData);
  return response;
}

export async function deleteTimeline(id, headerParams = null) {
  const response = await MISHEEL(headerParams).delete("/timeline/" + id);
  return response;
}

export async function postUser(params, headerParams = null) {
  const response = await MISHEEL(headerParams).post("/auth/user", {
    ...params,
  });
  return response;
}

export async function putUser(id, params, headerParams = null) {
  const response = await MISHEEL(headerParams).put("/auth/user/" + id, {
    ...params,
  });
  return response;
}

export async function deleteUser(id, headerParams = null) {
  const response = await MISHEEL(headerParams).delete("/auth/user/" + id);
  return response;
}

export async function getUser(headerParams = null) {
  const response = await MISHEEL(headerParams).get("/auth/user");
  return response;
}
// About
// Service
export async function getService(headerParams = null) {
  const response = await MISHEEL(headerParams).get("/service");
  return response;
}

export async function postServie(formData, headerParams = null) {
  headerParams = true;
  const response = await MISHEEL(headerParams).post("/service", formData);
  return response;
}

export async function deleteServie(id, headerParams = null) {
  const response = await MISHEEL(headerParams).delete("/service/" + id);
  return response;
}
