import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Appbar from "../../component/Appbar";
import FooterMain from "../../component/footerMain";
import ActivityRelated from "./activityRelated";
import DevelopmentHistory from "./developmentHistory";
import * as API from "../../api/request";
import Swal from "sweetalert2";
import HeroSlider from "../../component/common/HeroSlider";
const AboutUs = () => {
  const [list, setlist] = useState([]);
  const [images, setImages] = useState([]);
  useEffect(() => {
    API.getAbout()
      .then((res) => {
        setlist(res.data.data);
        const sliderImages = [res.data.data.cover, res.data.data.cover];
        setImages(sliderImages);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Алдаа гарлаа.",
          text: "Лист унших үед алдаа гарлаа дахин оролдоно уу.",
          confirmButtonColor: "#0f56b3",
        });
      });
  }, []);

  return (
    <>
      <div className=" w-screen  bg-[#ffffff]">
        <Appbar />
        <div className="w-full pt-18">
          <HeroSlider images={images} />
        </div>
      </div>

      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "40px",
          alignItems: "center",
          backgroundColor: "white",
          pt: "49px",
          pb: "100px",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "36px",
            width: "71%",
          }}
          className="about-mobile-gap about-mobile-width"
        >
          <div dangerouslySetInnerHTML={{ __html: list?.text }} />
        </Grid>
      </Grid>

      <FooterMain />
    </>
  );
};
export default React.memo(AboutUs);
