import React from "react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";

const HeroSlider = (props) => {
  const { images, height, smHeight, src } = props;

  return (
    <div
      // className={` ${smHeight ? smHeight : "h-[300px]"} ${
      //   height ? height : "md:h-screen"
      // } w-screen`}
      className="h-[600px]"
    >
      <div className="h-full">
        <Swiper
          spaceBetween={30}
          effect={"fade"}
          fadeEffect={true}
          speed={500}
          loop={true}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          centeredSlides={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, EffectFade, Navigation, Pagination]}
          className="mySwiper"
        >
          {images &&
            images.length > 0 &&
            images.map((imgSrc, index) => (
              <SwiperSlide key={index}>
                {src ? (
                  <div className="h-full w-full relative">
                    <img
                      src={`https://mmmall.mn${imgSrc}`}
                      alt={"image"}
                      className="w-full h-full"
                      style={{ objectFit: "fill" }}
                    />
                  </div>
                ) : (
                  <div className="h-full w-full relative">
                    <img
                      src={`https://mmmall.mn${imgSrc}`}
                      alt={"image"}
                      fill
                      className="w-full h-full"
                    />
                  </div>
                )}
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

export default HeroSlider;
