import background from "./backgroundImageBrandDetail.png";
import pillow from "./pillow.png";
import table from "./table.png";
import lamp from "./lamp.png";
import chair from "./chair.png";
import tree from "./tree.png";

const fileBD = {
  background: background,
  pillow: pillow,
  table: table,
  lamp: lamp,
  chair: chair,
  tree: tree
};
export default fileBD;
