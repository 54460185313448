import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import icons from "../../asset/icon/filePath";
import ArrowGreyBgColor from "./arrowGreyBgColor";

const BrandDetailModal = ({ handleClose, detail, txt }) => {
  const [index, setIndex] = useState(0);
  console.log(detail);
  const left = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const right = () => {
    if (detail.productImage.length !== index + 1) {
      setIndex(index + 1);
    }
  };

  return (
    <div
      className=" flex flex-col gap-12 max-h-screen max-w-[1280px] mx-auto overflow-y-auto  md:flex-row absolute top-20 left-1/2 transform -translate-x-1/2 -translate-y-1/5 w-full bg-opacity-60 bg-black text-white rounded-0 p-8 md:p-12 lg:p-14"
      style={{ maxHeight: "80vh" }}
    >
      {/* Close Button */}
      <div
        className="absolute top-4 right-4 cursor-pointer"
        onClick={handleClose}
      >
        <img
          src={icons.cancel} // Assuming you have a close icon image
          alt="Close"
          className="w-6 h-6"
        />
      </div>

      <div className="flex w-full mt-4 md:w-2/4 items-center space-y-4 md:space-y-8">
        <div className="flex w-full flex-row items-center justify-center space-x-4 md:space-x-8 relative">
          <ArrowGreyBgColor click={left} />
          <img
            style={{ objectFit: "cover" }}
            className="w-full"
            src={"https://mmmall.mn" + detail.productImage[index]}
            alt="detail"
          />
          <ArrowGreyBgColor click={right} />
          <Grid className="absolute top-0 right-0 bg-blue-700 rounded-5 text-white w-24 text-center">
            <Typography className="text-14">
              {index + 1}/{detail.productImage.length}
            </Typography>
          </Grid>
        </div>
      </div>
      <div className="flex flex-col w-full md:w-1/2 items-center text-justify space-y-4 md:space-y-8">
        <div className="flex w-full  justify-between">
          <Typography className="text-[16px] md:text-[20px] font-bold">
            {detail.productOpenStyle && txt.openStyle}
          </Typography>
          <Typography>{detail.productOpenStyle}</Typography>
        </div>
        <div className="flex w-full justify-between">
          <Typography className="text-[16px] md:text-[20px] font-bold">
            {detail.productStyle && txt.style}
          </Typography>
          <Typography>{detail.productStyle}</Typography>
        </div>
        <div className="flex w-full justify-between">
          <Typography className="text-[16px] md:text-[20px] font-bold">
            {detail.productColor && txt.color}
          </Typography>
          <Typography>{detail.productColor}</Typography>
        </div>
        <div className="flex w-full gap-12 justify-between">
          <Typography className="text-[16px] md:text-[20px] font-bold">
            {detail.productUsage && txt.usage}
          </Typography>
          <Typography>{detail.productUsage}</Typography>{" "}
        </div>
      </div>
    </div>
  );
};

export default React.memo(BrandDetailModal);
